<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Valuation Requests</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Valuation Requests"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.valuationRequestDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Valuation Request</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{
                    name: 'module-melrose-properties-valuation-requests-archived',
                  }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Valuation Requests</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="valuation_requests"
        class="mt-4"
        show-expand
        no-data-text="No Valuation Requests found"
      >
        <template v-slot:item.property="{ item }">
          <router-link
            v-if="item.property"
            :to="{
              name: 'module-melrose-properties-individual',
              params: { propertyId: item.property.id },
            }"
            v-html="item.property.address.short"
          ></router-link>
        </template>
        <template v-slot:item.customer="{ item }">
          {{ item.customer.full_name }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.valuationRequestDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="$refs.deleteValuationRequestDialog.openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pt-4 pb-4">
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Description</th>
                  <td v-html="item.description" class="pt-4 pb-4"></td>
                </tr>
              </tbody>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <valuation-request-dialog ref="valuationRequestDialog" />
    <delete-valuation-request-dialog ref="deleteValuationRequestDialog" />
  </div>
</template>

<script>
import ValuationRequestDialog from "./components/ValuationRequestDialog.vue";
import DeleteValuationRequestDialog from "./components/DeleteValuationRequestDialog.vue";

export default {
  components: {
    ValuationRequestDialog,
    DeleteValuationRequestDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          exact: true,
          to: { name: "module-melrose-properties-all" },
        },
        {
          text: "Valuation Requests",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Property", value: "property" },
        { text: "Customer", value: "customer" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
      deleteValuationRequest: {
        dialog: false,
        valuation_request: {},
        loading: false,
      },
    };
  },

  computed: {
    valuation_requests() {
      let valuation_requests =
        this.$store.getters["melrose/propertiesStore/valuation_requests"];

      if (this.searchTerm !== "") {
        valuation_requests = valuation_requests.filter((v) => {
          const property = v.address.short.toLowerCase();
          const customer = v.customer.full_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return property.includes(searchTerm) || customer.includes(searchTerm);
        });
      }

      return valuation_requests;
    },
  },
  methods: {
    openDelete(valuation_request) {
      this.deleteValuationRequest.valuation_request = valuation_request;
      this.deleteValuationRequest.dialog = true;
    },

    resetDelete() {
      this.deleteValuationRequest.dialog = false;
      this.deleteValuationRequest.valuation_request = {};
      this.deleteValuationRequest.loading = false;
    },

    saveDelete() {
      this.deleteValuationRequest.loading = true;

      this.$store
        .dispatch("melrose/propertiesStore/deleteValuationRequest", {
          appId: this.$route.params.id,
          valuationRequestId: this.deleteValuationRequest.valuation_request.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteValuationRequest.loading = false;
        });
    },
  },
};
</script>
