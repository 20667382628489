<template>
  <v-dialog v-model="deleteValuationRequest.dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">Archive Valuation Request</v-card-title>
      <v-card-text>Are you sure you wish to archive this?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
        <v-btn
          color="success"
          depressed
          :loading="deleteValuationRequest.loading"
          @click="saveDelete"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      deleteValuationRequest: {
        dialog: false,
        valuation_request: {},
        loading: false,
      },
    };
  },

  methods: {
    openDelete(valuation_request) {
      this.deleteValuationRequest.valuation_request = valuation_request;
      this.deleteValuationRequest.dialog = true;
    },

    resetDelete() {
      this.deleteValuationRequest.dialog = false;
      this.deleteValuationRequest.valuation_request = {};
      this.deleteValuationRequest.loading = false;
    },

    saveDelete() {
      this.deleteValuationRequest.loading = true;

      this.$store
        .dispatch("melrose/propertiesStore/deleteValuationRequest", {
          appId: this.$route.params.id,
          valuationRequestId: this.deleteValuationRequest.valuation_request.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteValuationRequest.loading = false;
        });
    },
  },
};
</script>
